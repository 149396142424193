import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";
import MiniHero from "../components/mini-hero"
import AboutMore from "../components/about-more";
// import Brands from "../components/brands";

const description  = 'Paper10 is has a dedicated team of specialists who are always keen to ensure that the needs of the customer comes first. Our Culture is unique and that makes us different.'
const heading ='What we offer'
const pagedescription = 'Our dedication to our customer is our strength'
const detail = 'We’re a leading digital agency fuelled to make results-led changes. We make every great idea into a greater reality. And build long-lasting partnerships with clients through our honesty, originality and positivity. We create, innovate and interrogate, to deliver services and products that make a difference. '
export default () => (
    <Layout>
        <SEO title="We are a team driven with goals and passion" description={description} keywords={[`paperten`, `history`, `solution`]} />
        <MiniHero title='About Us' />
        <AboutMore title='About Paperten' heading={heading} description={pagedescription} detail={detail} />
    </Layout>

    );
